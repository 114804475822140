import { isShift } from '~/utils/isShift'

export type Title =
  | 'Shift News - The Latest News Sports, and Weather'
  | 'OneNews.com - The Latest News Sports, and Weather'

function getRootTitle(): Title {
  if (isShift()) {
    return 'Shift News - The Latest News Sports, and Weather'
  }

  return 'OneNews.com - The Latest News Sports, and Weather'
}

export default function useTabTitle(): Ref<Title | undefined> {
  return useState<Title | undefined>('title', getRootTitle)
}
