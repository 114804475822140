<!-- This layout should be used with new header design -->
<template>
  <div class="ntp bg-onelaunch_ui_shades_50 dark:bg-radio_group_text_selected_dark">
    <Head>
      <slot name="headerScripts" />
    </Head>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-WNFNMPW"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
    <div class="ntp__content text-center dark:bg-radio_group_text_selected_dark">
      <slot name="headerSlot" />

      <div
        id="tiles"
        class="tiles border-t border-onelaunch_ui_shades_300 bg-onelaunch_ui_shades_100 dark:border-border_header_summary dark:bg-radio_group_bg_color"
      >
        <slot name="mostVisitedSlot" />
      </div>
      <slot name="newsSlot" />
    </div>
    <slot />
    <slot v-if="browser !== 'SHIFT'" name="footerSlot" />
  </div>
</template>

<script setup>
  import { useDocumentVisibility } from '@vueuse/core'
  import { setProfileProperty } from '~~/assets/js/newtabpage'
  import { isDevelopment } from '~~/assets/js/common'
  import { updateDoodleStore } from '~~/assets/js/doodle'
  import { useDoodleStore } from '~/store/DoodleStore'
  import { sendMixpanelEvent } from '~/utils/host-browser-apis'
  import { useAutoRefresh } from '~/composables/useAutoRefresh'

  const browser = useBrowser()
  const doodleImage = ref('')
  doodleImage.value = null

  onMounted(() => {
    useAutoRefresh()
    if (!isRootDomain()) {
      const faviconColor =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'white' : 'black'
      useHead({
        link: [
          {
            rel: 'icon',
            type: 'image/svg+xml',
            href: `data:image/svg+xml, <svg width="24" height="24" fill="${faviconColor}" xmlns="http://www.w3.org/2000/svg" ><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>`,
          },
        ],
      })
    }
  })

  const doodleStore = useDoodleStore()

  definePageMeta({
    layout: false,
    pageTransition: false,
  })

  const isRootDomain = () => {
    const route = useRoute()
    return route.name === 'index'
  }

  onBeforeMount(() => {
    if (isRootDomain()) {
      const title = useRootTitle()
      const faviconIcon = useRootFavicon()
      useHead({
        title,
        link: [
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: faviconIcon,
          },
        ],
      })
    } else {
      useHead({
        title: 'New Tab',
      })
    }
  })

  useHead({
    script: [
      {
        children:
          "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WNFNMPW');",
      },
    ],
  })

  const activeTab = useDocumentVisibility()
  let reload = true
  watch(
    activeTab,
    async (current) => {
      if (current === 'visible' && reload) {
        await sendMixpanelEvent('NtpPage_Viewed')
        reload = false
      }
    },
    { immediate: true }
  )

  onMounted(() => {
    updateDoodleStore()
    doodleImage.value = doodleStore.doodleImage
    // Not able to access the window object out of onMounted
    if (!isDevelopment()) {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'UA-62117061-22')
    }
    setProfileProperty('ntp_url', location.hostname + location.pathname)
  })
</script>

<style lang="scss" scoped>
  .ntp {
    min-height: 100vh;
    position: relative;

    &__content {
      padding-bottom: 39px;
    }
  }

  .tiles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 112px;
  }

  @media (max-width: 772px) {
    .tiles {
      height: auto;
    }
  }
</style>
<style lang="scss">
  .tiles {
    .most-visited__site:hover {
      text-decoration: underline;
    }
  }

  html::-webkit-scrollbar {
    display: none !important;
  }

  .searchbox--fixed {
    border-bottom: none !important;
  }
</style>
<style lang="scss">
  @import 'bootstrap/scss/bootstrap.scss';
  @import '~/assets/stylesheets/common.scss';

  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;
  }
</style>
